import { useEffect, useMemo, useState } from "react";
import "./App.css";
import PaginatedTable from "./components/PaginatedTable";

function App() {
  const columns = useMemo(
    () => [
      {
        Header: "Price History",
        columns: [
          {
            Header: "Value Date",
            accessor: "valueDate",
          },
          {
            Header: "Fund 1",
            accessor: "fundI",
          },
          {
            Header: "Fund 2",
            accessor: "fundII",
          },
          {
            Header: "Fund 3",
            accessor: "fundIII",
          },
          {
            Header: "Fund 4",
            accessor: "fundIV",
          },
          {
            Header: "Fund 6 (active)",
            accessor: "fundVIA",
          },
          {
            Header: "Fund 6 (retiree)",
            accessor: "fundVIB",
          },
        ],
      },
    ],
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    setIsLoading(true);
    fetch("http://info.nupemco.com:9090/fundsvalues/prices")
      .then((res) => res.json())
      .then((data) => setTableData(data));
    setIsLoading(false);
  }, []);

  if (isLoading) return "Data is loading ...";

  return (
    <div className="App">
      {tableData && <PaginatedTable columns={columns} data={tableData} />}
    </div>
  );
}

export default App;
